




















import { Component, Prop, Vue } from 'vue-property-decorator'
import copy from 'copy-to-clipboard'

@Component
export default class SectionActions extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly hash!: string

  @Prop({
    type: Boolean,
    required: true,
  }) readonly showResync!: boolean

  private isLoading = false

  private copyHash (): void {
    copy(this.hash)
  }

  private resync (): void {
    throw new Error('RESYNC NOT IMPLEMENTED')
  }
}
