
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ReorgItem } from './types'

@Component
export default class ReorgListItem extends Vue {
  @Prop({
    type: ReorgItem,
    required: true,
  }) readonly item!: ReorgItem
}
