


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { dateFormatter } from '@/utils/dateUtils'
import SectionActions from './SectionActions.vue'
import SectionStatus from './SectionStatus.vue'
import { ReorgsCountLatest } from '@/store/modules/reorgs/types'

@Component({
  components: {
    SectionActions,
    SectionStatus,
  },
})
export default class SectionHeadBlock extends Vue {
  @Prop({
    type: Date,
    required: true,
  }) readonly date!: Date

  @Prop({
    type: Number,
    required: true,
  }) readonly level!: number

  // TODO: refactor to either using class or use custom validator
  @Prop({
    type: Object,
    required: true,
  }) readonly reorgsCount!: ReorgsCountLatest

  get formattedDate (): string {
    return dateFormatter.format(this.date)
  }

  get formattedReorgs (): string {
    const { h, d, w } = this.reorgsCount
    return [h, d, w].join(' / ')
  }
}
