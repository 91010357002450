






























import { Component, Vue } from 'vue-property-decorator'
import ConnectionWidget from '@/components/ConnectionWidget/index.vue'
import ChartWidget from '@/components/ChartWidget/index.vue'
import FeedWidget from '@/components/FeedWidget/index.vue'

@Component({
  components: {
    ConnectionWidget,
    ChartWidget,
    FeedWidget,
  },
})
export default class Home extends Vue {
}
